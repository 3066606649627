import gsap, { TimelineMax } from 'gsap';

// OPEN MENU
export const staggerReveal = (node1, node2) => {
	gsap.from([ node1, node2 ], {
		duration: 0.8,
		height: 0,
		transformOrigin: 'right top',
		// skewXY:1 ,
		// skewYY:10,
		rotation: 0.01,
		rotate: 0.01,
		ease: 'power3.inOut',
		stagger: {
			amount: 0.1
		}
	});
};

// CLOSE MENU
export const staggerRevealClose = (node1, node2) => {
	gsap.to([ node1, node2 ], {
		duration: 0.8,
		height: 0,
		rotation: 0.01,
		rotate: 0.01,
		ease: 'power3.inOut',
		stagger: {
			amount: 0.07
		}
	});
};

// STAGGER THE LINKS TO APPEAR
export const staggerText = (node1, node2, node3, node4, node5) => {
	gsap.from([ node1, node2, node3, node4, node5 ], {
		rotation: 0.01,
		rotate: 0.01,
		duration: 0.8,
		y: 25,
		delay: 0.3,
		ease: 'power3.inOut',
		stagger: {
			amount: 0.3
		}
	});
};

export const staggerHeading = (node1, node2, node3, node4) => {
	gsap.from([ node1, node2, node3 ], {
		rotation: 0.01,
		rotate: 0.01,
		// duration: 0.7,
		// duration: 1,
		// duration: 2,
		duration: 1.75,
		// y: -5,
		opacity: 0,
		delay: 0.1,
		// ease: "power3.inOut",
		ease: 'sine.inOut',
		stagger: {
			// amount: 0.5
			// amount: 0.335
			// amount: 0.68
			amount: 0.43
		}
	});
	gsap.from(node4, {
		rotation: 0.01,
		rotate: 0.01,
		// duration: 0.75,
		// duration: 0.25,
		// duration: 0.5,
		// duration: 0.68,
		duration: 1.05,
		y: -4,
		opacity: 0,
		// delay: 1,
		// delay: 0.75,
		delay: 1,
		ease: 'sine.inOut'
	});
};
export const staggerHeadingGoodJournals = (node1, node2, node3, node4, node5) => {
	gsap.from([ node1, node2, node3, node4, node5 ], {
		rotation: 0.01,
		rotate: 0.01,
		duration: 2,
		// x: -5,
		opacity: 0,
		// delay: 0.1,
		// skewX: -15,
		// skewY: 7,
		// ease: "power3.inOut",
		ease: 'sine.inOut',
		stagger: {
			// amount: 0.5
			amount: 0.5
		}
	});
	// gsap.from(node5, {
	// 	duration: 0.75,
	// 	y: -4,
	// 	opacity: 0,
	// 	delay: 1.25,
	// 	ease: 'sine.inOut'
	// });
};

export const fadeInReveal = (node, duration) => {
	gsap.from(node, {
		rotation: 0.01,
		rotate: 0.01,
		duration,
		// delay: 0.75,
		// delay: 1.5,
		// delay: 0.5,
		autoAlpha: 0,
		ease: 'sine.inOut'
	});
};

export const fadeInBlur = (node, duration) => {
	gsap.from(node, {
		rotation: 0.01,
		rotate: 0.01,
		duration,
		delay: 0.75,
		filter: 'blur(1rem)',
		ease: 'sine.inOut'
	});
};

export const fadeInBlurText = (node, duration) => {
	gsap.from(node, {
		duration,
		// delay: 0.75,
		rotation: 0.01,
		rotate: 0.01,
		delay: 3,
		filter: 'blur(2rem)',
		autoAlpha: 0,
		y: 2,
		ease: 'sine.inOut'
	});
};

export const fadeInBlurTextTwo = (node, duration) => {
	gsap.to(node, {
		duration,
		rotation: 0.01,
		rotate: 0.01,
		// delay: 0.75,
		delay: 2,
		filter: 'blur(2rem)',
		autoAlpha: 1,
		// y: 2,
		ease: 'sine.inOut'
	});
};

// Hover on the link
export const handleHover = (e) => {
	gsap.to(e.target, {
		duration: 0.3,
		rotation: 0.01,
		rotate: 0.01,
		y: 3,
		// skewXX:1 ,
		// skewYX:10,
		color: '#fff',
		ease: 'power1.inOut'
	});
};

// Hover off the link
export const handleHoverExit = (e) => {
	gsap.to(e.target, {
		duration: 0.3,
		rotation: 0.01,
		rotate: 0.01,
		y: -3,
		// skewXX:1 ,
		// skewYX:10,
		color: '#181818',
		ease: 'power1.inOut'
	});
};

export const handleHoverMenuItem = (e) => {
	gsap.to(e.target, {
		duration: 0.1,
		rotation: 0.01,
		rotate: 0.01,
		y: 2.5,
		// skewXX:1 10,
		// skewYX:1010,
		color: '#181818',
		ease: 'sine.inOut'
	});
};

// Hover off the link
export const handleHoverExitMenuItem = (e) => {
	gsap.to(e.target, {
		duration: 0.1,
		rotation: 0.01,
		rotate: 0.01,
		y: -1,
		// skewXX:1 ,
		// skewYX:10,
		color: '#181818',
		ease: 'sine.inOut'
	});
};

// adds city image once you hover on
export const handleCity = (city, target) => {
	gsap.to(target, {
		duration: 0,
		background: `url(${city}) center center`
	});
	gsap.to(target, {
		duration: 0.4,
		opacity: 1,
		ease: 'power3.inOut'
	});
	gsap.from(target, {
		duration: 0.4,
		// skewXY:1 ,
		// skewYY:10,
		transformOrigin: 'right top'
	});
};

// Removes the city image once you hover off
export const handleCityReturn = (target) => {
	gsap.to(target, {
		duration: 0
		// skewXY:1
		// skewYY:10
	});
	gsap.to(target, {
		duration: 0.4,
		opacity: 0
		// skewXY:1
		// skewYY:10
	});
};

export const fadeIn = (target) => {
	gsap.to(target, {
		opacity: 1,
		duration: 0.05
	});
};
export const fadeOut = (target, duration) => {
	gsap.to(target, {
		opacity: 0,
		duration: duration || 0.05
	});
};

export const fadeInDown = (node, duration = 0.5, delay = 1.75) => {
	gsap.from(node, {
		rotation: 0.01,
		rotate: 0.01,
		y: -10,
		// duration: .5,
		duration,
		// delay: 1.75,
		delay,
		opacity: 0,
		ease: 'sine.inOut'
	});
};

export const fadeInUp = (node, duration, delay) => {
	gsap.from(node, {
		rotation: 0.01,
		rotate: 0.01,
		y: 20,
		duration: duration || 0.5,
		// duration,
		delay: delay || 0.25,
		// delay,
		// skewX: -15,
		// skewY: 7,
		opacity: 0,
		ease: 'sine.inOut'
	});
};

export const fadeInConfirmHeading = (node1, node2, duration, delay) => {
	gsap.from(node1, {
		rotation: 0.01,
		rotate: 0.01,
		// y: 20,
		duration: duration || 0.5,
		// duration,
		delay: delay || 0.25,
		// delay,
		// skewX: -15,
		// skewY: 7,
		opacity: 0,
		ease: 'sine.inOut'
	});

	gsap.from(node2, {
		rotation: 0.01,
		rotate: 0.01,
		// y: 20,
		duration: duration || 0.5,
		// duration,
		delay: delay || 0.25,
		// delay,
		// skewX: -15,
		// skewY: 7,
		opacity: 0,
		ease: 'sine.inOut'
	});
};

export const intersectionFadeOut = (node) => {
	const rootMargin = { root: null, rootMargin: '0px 0px -80px 0px' };
	// const rootMargin = { root: null, rootMargin: '0px 0px -400px 0px' };
	let observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting || entry.intersectionRatio > 0) {
				// gsap.to(node, 0.5, { autoAlpha: 0, ease: 'sine.out', rotation: 0.01 });
				gsap.to(node, 0.5, { autoAlpha: 0, ease: 'sine.out', rotate: 0.01 });

				observer.unobserve(node);
				// observer.disconnect();
			} else {
			}
		});
	}, rootMargin);

	observer.observe(node);
};

export const fillInColor = (node, color) => {
	gsap.set(node, {
		rotation: 0.01,
		rotate: 0.01,
		fill: color,
		duration: 1,
		ease: 'sine.inOut'
	});
};

export const staggerDots = (dots, duration) => {
	let tl = new TimelineMax();

	// console.log('gsap.staggerFrom', tl.staggerFrom);
	tl.staggerFrom(
		dots,
		duration,
		{
			// delay: 0.75,
			// delay: 3,
			filter: 'blur(1rem)',
			autoAlpha: 0,
			rotation: 0.01,
			rotate: 0.01,
			y: 2
			// ease: 'sine.inOut'
		},
		0.03
	);
};
// export const staggerObjs = (dots, duration) => {
// 	console.log('.dots', dots);
// 	let tl = new TimelineMax();

// 	// console.log('gsap.staggerFrom', tl.staggerFrom);
// 	tl
// 		.staggerFrom(
// 			dots,
// 			duration,
// 			{
// 				// delay: 0.75,
// 				// delay: 3,
// 				filter: 'blur(1rem)',
// 				autoAlpha: 0,
// 				y: 2
// 				// ease: 'sine.inOut'
// 			},
// 			0.1
// 			// 0.2
// 		)
// 		.staggerFrom(dots, Math.floor(duration / 2), {
// 			filter: 'blur(0rem)',
// 			y: 0,
// 			autoAlpha: 1
// 		});
// };

export const staggerObjs = (node1, node2, node3, node4, node5, node6, node7) => {
	let tl = new TimelineMax();
	tl
		.to([ node1, node2, node3, node4, node5, node6, node7 ], {
			rotation: 0.01,
			duration: 0.7,
			rotate: '-360deg',
			autoAlpha: 1,
			x: 2,
			y: -4,
			ease: 'power4.inOut',
			stagger: {
				amount: 0.1
			}
		})
		.to([ node7, node6, node5, node4, node3, node2, node1 ], {
			duration: 0.7,
			rotation: 0.01,
			rotate: 0.01,
			x: -2,
			rotate: '360deg',
			y: 4,
			autoAlpha: 0,
			ease: 'power4.inOut',
			stagger: {
				amount: 0.1
			}
		});
};

export const revealBlues = (node1, node2, node3, node4, node5, node6) => {
	// export const revealBlues = (node1) => {
	let tl = new TimelineMax();

	tl
		.to(node1, {
			rotation: 0.01,
			rotate: 0.01,
			duration: 0.3,
			autoAlpha: 1,
			y: -4
		})
		.to(
			node2,
			{
				rotation: 0.01,
				rotate: 0.01,
				duration: 0.3,
				autoAlpha: 1,
				y: -4
			},
			'-=.25'
		)
		.to(
			node3,
			{
				rotation: 0.01,
				rotate: 0.01,
				duration: 0.3,
				autoAlpha: 1,
				y: -4
			},
			'-=.25'
		)
		.to(
			node4,
			{
				rotation: 0.01,
				rotate: 0.01,
				duration: 0.3,
				autoAlpha: 1,
				y: -4
			},
			'-=.25'
		)
		.to(
			node5,
			{
				rotation: 0.01,
				rotate: 0.01,
				duration: 0.3,
				autoAlpha: 1,
				y: -4
			},
			'-=.25'
		)
		.to(
			node6,
			{
				rotation: 0.01,
				rotate: 0.01,
				duration: 0.3,
				autoAlpha: 1,
				y: -4
			},
			'-=.25'
		)
		// fade out

		.to(node6, {
			rotation: 0.01,
			rotate: 0.01,
			duration: 0.3,
			autoAlpha: 0,
			y: 4,
			delay: 0.3
		})
		.to(
			node5,
			{
				rotation: 0.01,
				rotate: 0.01,
				duration: 0.3,
				autoAlpha: 0,
				y: 4
			},
			'-=.25'
		)
		.to(
			node4,
			{
				rotation: 0.01,
				rotate: 0.01,
				duration: 0.3,
				autoAlpha: 0,
				y: 4
			},
			'-=.25'
		)
		.to(
			node3,
			{
				rotation: 0.01,
				rotate: 0.01,
				duration: 0.3,
				autoAlpha: 0,
				y: 4
			},
			'-=.25'
		)
		.to(
			node2,
			{
				rotation: 0.01,
				rotate: 0.01,
				duration: 0.3,
				autoAlpha: 0,
				y: 4
			},
			'-=.25'
		)
		.to(
			node1,
			{
				rotation: 0.01,
				rotate: 0.01,
				duration: 0.3,
				autoAlpha: 0,
				y: 4
			},
			'-=.25'
		);

	// tl
	// 	.to([ node1, node2, node3, node4, node5, node6 ], {
	// 		// 		rotation: 0.01,
	// 		rotate: 0.01,
	// 		duration: 0.75,
	// 		autoAlpha: 1,
	// 		y: -4,
	// 		ease: 'power4.inOut',
	// 		stagger: {
	// 			amount: 0.1
	// 		}
	// 	})
	// 	.to([ node6, node5, node4, node3, node2, node1 ], {
	// 		duration: 0.7,
	// 		rotation: 0.01,
	// 		rotate: 0.01,
	// 		y: 4,
	// 		autoAlpha: 0,
	// 		ease: 'power4.inOut',
	// 		stagger: {
	// 			amount: 0.2
	// 		}
	// 	});
};
